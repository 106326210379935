<template>
  <slot></slot>
</template>
<script setup lang="ts">
onMounted(() => {
  const inputs = document.querySelectorAll("input");
  const areas = document.querySelectorAll("textarea");
  const selects = document.querySelectorAll("select");
  [...inputs, ...areas, ...selects].forEach((input) => {
    input.addEventListener("blur", (event) => {
      if ((event as any)?.target?.value) {
        input.classList.add("is-valid");
      } else {
        input.classList.remove("is-valid");
      }
    });
  });
});
</script>
